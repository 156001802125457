import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Field } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAlert, CustomSelect, CustomTextField } from 'components/Shared';
import { UserFormAction } from 'pages/User/UserForm';

interface PersonalDataFormProps {
  municipalitiesOptions: { uuid: string; name: string }[];
  values: any;
  errors: any;
  touched: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  duplicateError: string | null;
  fieldsRef: {
    cpfRef: React.RefObject<HTMLInputElement>;
    emailRef: React.RefObject<HTMLInputElement>;
  };
  clearDuplicateError: () => void;
  userFormAction: UserFormAction;
}

const formatPhoneNumber = (phone: string) => {
  const cleaned = phone.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

  if (match) {
    const intlCode = match[1];
    const part1 = match[2];
    const part2 = match[3];
    return `(${intlCode}) ${part1}-${part2}`;
  }

  return phone;
};

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  municipalitiesOptions,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  duplicateError,
  fieldsRef,
  clearDuplicateError,
  userFormAction,
}) => {
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    const formatted = formatPhoneNumber(value);
    setFieldValue('personalData.phone', formatted);
  };

  const errorMessage = () => {
    if (errors.personalData?.cpf && touched.personalData?.cpf) {
      return 'Não foi possível realizar a validação do "CPF". Informe-o corretamente abaixo.';
    }
    if (errors.personalData?.email && touched.personalData?.email) {
      return 'O e-mail informado é inválido. Corrija-o abaixo.';
    }
    return '';
  };

  return (
    <Box sx={{ width: '1152px', marginTop: '20px' }}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Dados pessoais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errorMessage() && <CustomAlert severity='error' message={errorMessage()} />}
          {duplicateError && <CustomAlert severity='error' message={duplicateError} />}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.name'
                as={CustomTextField}
                label='Nome Completo'
                variant='outlined'
                size='small'
                disabled={userFormAction === UserFormAction.EDITAR}
                value={values.personalData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.personalData?.name && Boolean(errors.personalData?.name)}
                helperText={touched.personalData?.name && errors.personalData?.name}
                InputLabelProps={{ shrink: true }}
              />
              <Field name='personalData.cpf'>
                {({ field }: any) => (
                  <ReactInputMask
                    {...field}
                    mask='999.999.999-99'
                    maskChar={null}
                    onChange={(e) => {
                      field.onChange(e);
                      clearDuplicateError();
                    }}
                  >
                    {(inputProps: any) => (
                      <CustomTextField
                        {...inputProps}
                        label='CPF'
                        variant='outlined'
                        size='small'
                        inputRef={fieldsRef.cpfRef}
                        error={touched.personalData?.cpf && Boolean(errors.personalData?.cpf)}
                        helperText={touched.personalData?.cpf && errors.personalData?.cpf}
                        InputProps={{
                          ...inputProps,
                          disabled: userFormAction === UserFormAction.EDITAR,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </ReactInputMask>
                )}
              </Field>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.email'
                as={CustomTextField}
                label='Email'
                variant='outlined'
                size='small'
                value={values.personalData.email}
                onChange={(e: React.ChangeEvent<any>) => {
                  handleChange(e);
                  clearDuplicateError();
                }}
                onBlur={handleBlur}
                inputRef={fieldsRef.emailRef}
                error={touched.personalData?.email && Boolean(errors.personalData?.email)}
                helperText={touched.personalData?.email && errors.personalData?.email}
                InputLabelProps={{ shrink: true }}
              />
              <Field name='personalData.phone'>
                {({ field, form: { setFieldValue, errors, touched } }: any) => (
                  <CustomTextField
                    {...field}
                    label='Telefone'
                    variant='outlined'
                    size='small'
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneChange(e, setFieldValue)}
                    onBlur={() => setFieldValue('personalData.phone', field.value, true)}
                    error={touched.personalData?.phone && Boolean(errors.personalData?.phone)}
                    helperText={touched.personalData?.phone && errors.personalData?.phone}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Field>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <Field
                name='personalData.position'
                as={CustomTextField}
                label='Cargo'
                variant='outlined'
                size='small'
                value={values.personalData.position}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.personalData?.position && Boolean(errors.personalData?.position)}
                helperText={touched.personalData?.position && errors.personalData?.position}
                InputLabelProps={{ shrink: true }}
              />
              <Field
                name='personalData.occupation'
                as={CustomTextField}
                label='Função'
                variant='outlined'
                size='small'
                value={values.personalData.occupation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.personalData?.occupation && Boolean(errors.personalData?.occupation)}
                helperText={touched.personalData?.occupation && errors.personalData?.occupation}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '50%', paddingRight: '8px' }}>
              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 400,
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    color: '#00000099',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  }}
                >
                  Município
                </InputLabel>
                <Field
                  name='personalData.municipality'
                  as={CustomSelect}
                  labelId='municipality-label'
                  id='municipality-select'
                  label='Município'
                  size='small'
                  disabled={userFormAction === UserFormAction.EDITAR}
                  value={values.personalData.municipality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.personalData?.municipality && Boolean(errors.personalData?.municipality)}
                >
                  {municipalitiesOptions.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Field>
                {touched.personalData?.municipality && errors.personalData?.municipality && (
                  <Typography color='error' variant='caption' textAlign={'left'}>
                    {errors.personalData?.municipality}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PersonalDataForm;
