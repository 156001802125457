import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface UserCardStatusProps {
  status: string;
}

const UserTermCardStatus: React.FC<UserCardStatusProps> = ({ status }) => {
  const getStatusStyles = () => {
    if (status === 'Ativo') {
      return {
        icon: <CheckCircleOutlineIcon sx={{ color: '#2E7D32', fontSize: '20px' }} />,
        text: 'Situação: Ativo',
        backgroundColor: '#EAF2EA',
      };
    } else if (status === 'Inativo') {
      return {
        icon: <WarningAmberOutlinedIcon sx={{ color: '#C62828', fontSize: '20px' }} />,
        text: 'Situação: Inativo',
        backgroundColor: '#D32F2F0A',
      };
    } else {
      return {
        icon: null,
        text: '',
        backgroundColor: '#E0E0E0',
      };
    }
  };

  const statusStyles = getStatusStyles();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: statusStyles.backgroundColor,
        padding: '8px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {statusStyles.icon}
      <Typography sx={{ color: '#00000099', fontWeight: 400, fontSize: '16px', lineHeight: '26.56px', letterSpacing: '0.4px', paddingLeft: '8px' }}>
        {statusStyles.text}
      </Typography>
    </Box>
  );
};

export default UserTermCardStatus;
