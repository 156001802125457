import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, IconButton, LinearProgress, LinearProgressProps, Paper, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BoundingBox from '../../assets/icons/bounding-box.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { UserFormAction } from 'pages/User/UserForm';

interface AttachFileFormProps {
  userFormAction: UserFormAction;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box>
        <Typography variant='caption'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const AttachFileFormSchema = Yup.object().shape({});

const AttachFileForm: React.FC<AttachFileFormProps> = ({ userFormAction }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const isValidType = ['image/png', 'image/jpeg', 'application/pdf'].includes(selectedFile.type);
      const isValidSize = selectedFile.size <= 8 * 1024 * 1024 || (selectedFile.type === 'application/pdf' && selectedFile.size <= 20 * 1024 * 1024);

      if (isValidType && isValidSize) {
        setFile(selectedFile);
        setShowUploadProgress(true);
        setUploadError(null);

        const uploadInterval = setInterval(() => {
          setUploadProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(uploadInterval);
              setUploadCompleted(true);
              return 100;
            }
            return prevProgress + 10;
          });
        }, 300);
      } else {
        setUploadError(`Arquivo inválido. ${selectedFile.type}, maior que ${selectedFile.type === 'application/pdf' ? '20MB' : '8MB'}`);
        setFile(selectedFile);
        setUploadCompleted(false);
      }
    }
  };

  const handleUploadClick = () => {
    inputFileRef.current?.click();
  };

  return (
    <Formik
      initialValues={{
        group: '',
        profile: '',
        permission: '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={AttachFileFormSchema}
      onSubmit={(values) => {
        // Submissão dos dados
        console.log(values);
      }}
    >
      {/* {({ errors, touched, handleSubmit }) =>  */}
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} style={{ width: '1152px', height: '252px', marginTop: '20px', backgroundColor: '#FFFFFF' }}>
          <Box sx={{ marginLeft: '15px' }}>
            <Box>
              <Typography variant='h6' sx={{ textAlign: 'left' }}>
                Anexar arquivo
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20.02px',
                  letterSpacing: '0.17px',
                  textAlign: 'left',
                  color: '#00000099',
                  '&.Mui-focused': {
                    color: '#00000099',
                  },
                }}
              >
                Caso a permissão do usuário que o cadastro que estiver sendo atualizado seja Representante Legal de qualquer grupo, anexe o arquivo
                correspondente.
              </Typography>
            </Box>

            <Box sx={{ flexDirection: 'column', textAlign: 'left' }}>
              <Button
                variant='outlined'
                startIcon={<AttachFileIcon />}
                onClick={handleUploadClick}
                disabled={userFormAction === UserFormAction.EDITAR}
                sx={{
                  border: '1px solid #0048B680',
                  borderRadius: '4px',
                  marginTop: '15px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.4px',
                    textAlign: 'left',
                  }}
                >
                  ANEXAR ARQUIVO
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20.02px',
                  letterSpacing: '0.17px',
                  textAlign: 'left',
                  color: '#00000099',
                  '&.Mui-focused': {
                    color: '#00000099',
                  },
                }}
              >
                Imagens em PNG, JPG (máximo de 8 MB) ou PDF (máximo de 20MB)
              </Typography>

              <input type='file' ref={inputFileRef} style={{ display: 'none' }} onChange={handleFileChange} accept='.png, .jpg, .jpeg, .pdf' />
            </Box>

            {file && (
              <Box sx={{ marginTop: '20px', marginRight: '20px' }}>
                <Paper
                  elevation={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #00000012',
                    width: '100%',
                    height: 'auto',
                    background: '#F5F5F5',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ marginLeft: '10px' }}>
                          <img src={BoundingBox} alt='Bounding Box' />
                        </Box>
                        <Box sx={{ marginLeft: '20px' }}>
                          <Typography
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              textAlign: 'left',
                            }}
                          >
                            {uploadError ? 'O anexo falhou' : file?.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '20.02px',
                              letterSpacing: '0.17px',
                              textAlign: 'left',
                            }}
                          >
                            {uploadError ? uploadError : !uploadCompleted ? 'Anexando arquivo...' : `${file?.type}, ${(file.size / 1024).toFixed(2)} KB`}
                          </Typography>
                          {showUploadProgress && <LinearProgressWithLabel variant='determinate' value={uploadProgress} sx={{ width: '925px' }} />}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          alignItems: 'center',
                        }}
                      >
                        <Box>
                          <IconButton aria-label='delete' size='large' onClick={() => setFile(null)}>
                            <CloseIcon fontSize='inherit' />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            width: '32px',
                            height: '80px',
                            background: uploadError ? '#D32F2F' : '#2E7D32',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {uploadError ? <ErrorOutlineIcon /> : <CheckCircleOutlineIcon />}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AttachFileForm;
